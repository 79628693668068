import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    alignItems: 'center',
    flexDirection: 'column',
  },
  grow: {
    flexGrow: 1,
    textAlign: 'center',
  },
  zoomed: {
    transition: 'height 450ms',
    height: '1vh',
  },
  emptyDiv: {
    transition: 'height 450ms',
    height: '5vh',
  },
}));

export default function Aligner({ top, middle, bottom, zoomed }) {
  const classes = useStyles();
  return (
    <div className={classes.flexContainer}>
      <div className={zoomed ? classes.zoomed : classes.emptyDiv}>{top}</div>
      <div className={classes.grow}>{middle}</div>
      <div className={zoomed ? classes.zoomed : classes.emptyDiv}>{bottom}</div>
    </div>
  );
}
