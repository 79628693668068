import React, { useState } from 'react';

import { Button } from '@mui/material';
import DownloadDialog from './DownloadDialog';
import { useTranslation } from 'react-i18next';
import { dataTestValue } from '../helpers';

function dataTestButton(item) {
  return dataTestValue('downloadbutton', 'button', item);
}
export default function DownloadButton({ viewList, regionList, graphMap }) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        data-test={dataTestButton('download')}
        sx={{
          color: 'secondary.contrastText',
          bgcolor: 'other.red',
          borderRadius: 5,
          px: 4,
          textTransform: 'none',
          '&&:hover': {
            bgcolor: 'other.orange',
          },
        }}
        onClick={() => setOpen(true)}
      >
        {t('download')}
      </Button>
      <DownloadDialog
        open={open}
        setOpen={setOpen}
        viewList={viewList}
        regionList={regionList}
        graphMap={graphMap}
      />
    </>
  );
}
