import { AppBar, Breadcrumbs, Toolbar } from '@mui/material';
import Grid from '@mui/material/Grid2';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { dataTestValue } from '../helpers';

function dataTestBreadcrumbs(item) {
  return dataTestValue('footerbar', 'breadcrumbs', item);
}

function handleClick(event) {
  event.preventDefault();
}

export default function FooterBar({ children, downloadButton }) {
  const theme = useTheme();

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        top: 'auto',
        bottom: 0,
        background: 'transparent',
        boxShadow: 'none',
      }}
    >
      <Toolbar disableGutters>
        <Grid
          container
          size={10}
          sx={{
            bgcolor: 'secondary.main',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            borderRadius: theme.spacing(0, 5, 5, 0),
          }}
        >
          <div role="presentation" onClick={handleClick}>
            <Breadcrumbs
              data-test={dataTestBreadcrumbs('links')}
              sx={{
                ml: 2,
                display: 'flex',
                color: 'secondary.contrastText',
              }}
              separator={<NavigateNextIcon fontSize="large" />}
            >
              {children}
            </Breadcrumbs>
          </div>
          {downloadButton}
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
