import Divider from '@mui/material/Divider';
import React from 'react';

export default function CommonDivider({
  variant = 'middle',
  sx = {
    my: 2.5,
  },
  children,
}) {
  return (
    <Divider
      sx={[
        {
          ...sx,
        },
        {
          '&.MuiDivider-root': {
            '&::before': {
              borderTop: 'thin solid',
            },
            '&::after': {
              borderTop: 'thin solid',
            },
          },
        },
      ]}
      variant={variant}
    >
      {children}
    </Divider>
  );
}
