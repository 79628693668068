import React, { useContext, useMemo } from 'react';

import { SvgIcon } from '@mui/material';

import { ReactComponent as AB } from '../assets/images/regions/AB.svg';
import { ReactComponent as BC } from '../assets/images/regions/BC.svg';
import { ReactComponent as CANADA } from '../assets/images/regions/BlackMapWithNoTitles.svg';
import { ReactComponent as MB } from '../assets/images/regions/MB.svg';
import { ReactComponent as NB } from '../assets/images/regions/NB.svg';
import { ReactComponent as NL } from '../assets/images/regions/NL.svg';
import { ReactComponent as NS } from '../assets/images/regions/NS.svg';
import { ReactComponent as NT } from '../assets/images/regions/NT.svg';
import { ReactComponent as NU } from '../assets/images/regions/NU.svg';
import { ReactComponent as ON } from '../assets/images/regions/ON.svg';
import { ReactComponent as PE } from '../assets/images/regions/PE.svg';
import { ReactComponent as QC } from '../assets/images/regions/QC.svg';
import { ReactComponent as SK } from '../assets/images/regions/SK.svg';
import { ReactComponent as YT } from '../assets/images/regions/YT.svg';

import { RegionContext } from '../contexts/RegionContext';

export function AutomaticRegionIcon({ large = false }) {
  const { region } = useContext(RegionContext);
  const chosen = useMemo(() => {
    return region ? region : 'Canada';
  }, [region]);
  return <RegionIcon region={chosen} large={large} />;
}

export default function RegionIcon({ region, large = false }) {
  let comp;
  switch (region) {
    case 'BC':
      comp = BC;
      break;
    case 'AB':
      comp = AB;
      break;
    case 'MB':
      comp = MB;
      break;
    case 'SK':
      comp = SK;
      break;
    case 'ON':
      comp = ON;
      break;
    case 'QC':
      comp = QC;
      break;
    case 'PE':
      comp = PE;
      break;
    case 'NS':
      comp = NS;
      break;
    case 'NB':
      comp = NB;
      break;
    case 'NL':
      comp = NL;
      break;
    case 'YT':
      comp = YT;
      break;
    case 'NT':
      comp = NT;
      break;
    case 'NU':
      comp = NU;
      break;
    case 'CANADA':
    default:
      comp = CANADA;
      break;
  }
  const extraParams = large ? { fontSize: 'large' } : {};
  return <SvgIcon component={comp} inheritViewBox {...extraParams} />;
}
