import React from 'react';
import { Tooltip, Typography } from '@mui/material';

export default function CommonTooltip({
  arrow = true,
  children,
  placement = 'top',
  title,
}) {
  return (
    <Tooltip
      title={
        <Typography variant="subtitle2" sx={{ whiteSpace: 'pre-line' }}>
          {title}
        </Typography>
      }
      placement={placement}
      arrow={arrow}
    >
      {children}
    </Tooltip>
  );
}
