import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  Link,
  Slide,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useContext, useState } from 'react';

import NaviusLogo from '../common/NaviusLogo.js';
import LanguageButton from '../common/LanguageButton.js';
import MenuIcon from '@mui/icons-material/Menu';
import HelpIcon from '@mui/icons-material/Help';
import MobileSidebar from '../common/MobileSidebar';
import { Link as ReactLink } from 'react-router-dom';
import ThemeButton from '../common/ThemeButton.js';
import { useTranslation } from 'react-i18next';

import { DeviceContext } from '../Contexts';
import { ParamsContext } from '../contexts/ParamsContext';
import { ViewContext } from '../contexts/ViewContext';
import { dataTestValue } from '../helpers';

function dataTestIconButton(item) {
  return dataTestValue('header', 'iconbutton', item);
}

function dataTestLink(item) {
  return dataTestValue('header', 'link', item);
}

function dataTestTab(item) {
  return dataTestValue('header', 'tab', item);
}

function MobileControls({ graphMap, viewList, regionList }) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  return (
    <>
      <IconButton onClick={handleDrawerToggle}>
        <MenuIcon></MenuIcon>
      </IconButton>
      <Drawer
        variant="temporary"
        anchor={'right'}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{
          '& .MuiDrawer-paperAnchorRight': {
            bgcolor: 'primary.main',
            color: 'primary.contrastText',
            height: '100vh',
          },
          zIndex: 1300,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <MobileSidebar
          graphMap={graphMap}
          viewList={viewList}
          regionList={regionList}
        />
      </Drawer>
    </>
  );
}

function DesktopControls() {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: 'inline-flex' }}>
      <LanguageButton />
      <Box
        sx={{
          alignItems: 'center',
          display: 'inline-flex',
          border: '0.1px solid grey',
          borderRadius: 2,
          mx: 0.5,
        }}
      >
        <IconButton
          data-test={dataTestIconButton('faq')}
          sx={{
            color: 'inherit',
            p: 0,
          }}
          component={Link}
          href={t('faq_link')}
        >
          <HelpIcon />
        </IconButton>
      </Box>
      <ThemeButton />
    </Box>
  );
}

function UpperToolbar({ height, viewList, regionList, graphMap }) {
  const { t } = useTranslation();
  const isMobileView = useContext(DeviceContext);

  return (
    <Toolbar
      disableGutters
      sx={{
        height: height,
        bgcolor: 'secondary.main',
      }}
    >
      <Grid
        container
        size={12}
        sx={{
          bgcolor: 'secondary.main',
          color: 'secondary.contrastText',
          justifyContent: 'space-between',
          alignContent: 'center',
        }}
      >
        <Grid
          sx={{
            alignSelf: 'center',
            maxHeight: height,
          }}
          size={1}
        >
          <NaviusLogo height={height} />
        </Grid>
        <Grid
          sx={{
            alignSelf: 'center',
          }}
          size={8}
        >
          <Link
            data-test={dataTestLink('indexhtml')}
            underline="none"
            sx={{
              alignItems: 'center',
              color: 'secondary.contrastText',
              display: 'flex',
              pl: 2,
            }}
            component={ReactLink}
            to="/index.html"
          >
            <Typography variant="h6">{t('heading')}</Typography>
          </Link>
        </Grid>
        <Grid
          size={3}
          sx={{
            alignSelf: 'center',
            textAlign: 'right',
          }}
        >
          {isMobileView ? (
            <MobileControls
              graphMap={graphMap}
              viewList={viewList}
              regionList={regionList}
            />
          ) : (
            <DesktopControls />
          )}
        </Grid>
      </Grid>
    </Toolbar>
  );
}

function BottomToolbar({ height, viewList }) {
  const { t } = useTranslation();
  const isMobileView = useContext(DeviceContext);
  const { getUrl } = useContext(ParamsContext);
  const { view: viewFromContext } = useContext(ViewContext);

  return (
    <Toolbar disableGutters>
      <Tabs
        sx={{
          width: '100vw',
        }}
        TabIndicatorProps={{
          sx: {
            backgroundColor: 'other.orange',
          },
        }}
        scrollButtons={false}
        value={viewFromContext === null ? false : viewFromContext}
        variant={'fullWidth'}
        textColor={'inherit'}
      >
        {viewList.map((view) => {
          return (
            <Tab
              data-test={dataTestTab(view.to)}
              key={view.label}
              value={view.to}
              sx={{
                bgcolor:
                  view.to === viewFromContext ? 'other.orange' : view.color,
                minHeight: height,
                minWidth: '65px',
                flexDirection: isMobileView ? 'column' : 'row',
                maxWidth: view.to === 'map' ? '0%' : 'none',
                color:
                  view.to === viewFromContext ? 'other.white' : 'other.black',
                '& .MuiTab-iconWrapper': {
                  transition: 'transform 250ms',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                  },
                },
              }}
              component={ReactLink}
              icon={view.largeIcon}
              label={isMobileView ? null : t(view.label)}
              to={getUrl({
                view: view.to,
              })}
            />
          );
        })}
      </Tabs>
    </Toolbar>
  );
}

export default function Header({
  graphMap,
  regionList = [],
  viewList = [],
  scrollTrigger,
  headerHeight,
}) {
  return (
    <>
      <CssBaseline />
      <Slide appear={false} direction="down" in={!scrollTrigger}>
        <AppBar position="static" elevation={0}>
          <UpperToolbar
            height={`calc(${headerHeight} / 2)`}
            graphMap={graphMap}
            viewList={viewList}
            regionList={regionList}
          />
          <BottomToolbar
            height={`calc(${headerHeight} / 2)`}
            viewList={viewList}
          />
        </AppBar>
      </Slide>
    </>
  );
}
