import {
  Box,
  Dialog,
  DialogContent,
  Link,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import React from 'react';

import RegionIcon from '../components/RegionIcon';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useTranslation } from 'react-i18next';
import { dataTestValue } from '../helpers';

function dataTestLink(item) {
  return dataTestValue('downloaddialog', 'link', item);
}
function Documentation() {
  const { t } = useTranslation();

  return (
    <Box
      color="text.primary"
      sx={{
        p: 3,
        mx: 0,
        mb: 3,
        border: 1,
        borderColor: 'other.orange',
        borderRadius: 1,
      }}
    >
      <PictureAsPdfIcon sx={{ verticalAlign: 'bottom' }} />
      <Link
        data-test={dataTestLink('modeldocumentation')}
        component="a"
        sx={{ mx: 2 }}
        color="text.primary"
        href="/data/navius_research_gtech_iesd_model_documentation.pdf"
        download="navius_research_gtech_iesd_model_documentation.pdf"
      >
        {t('modeldocumentation')}
      </Link>
    </Box>
  );
}

export default function DownloadDialog({ open, setOpen, regionList }) {
  const { t, i18n } = useTranslation();
  const handleClose = () => setOpen(false);

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
      <DialogContent>
        <Documentation />
        <Grid container spacing={1}>
          <Grid size={{xs: 12}} sx={{ textAlign: 'center', pb: 2 }}>
            <Typography variant="h6">{t('download_regional_data')}</Typography>
          </Grid>
          {regionList.map((r) => {
            const target = `/data/${i18n.resolvedLanguage}/${r.regional_data}`;
            return (
              <Grid key={r.label} size={{xs: 6, sm: 3}}>
                <Link
                  data-test={dataTestLink(r.label)}
                  component="a"
                  color="text.primary"
                  href={target}
                  download={r.regional_data}
                >
                  <Box sx={{ textAlign: 'center' }}>
                    <Box>
                      <RegionIcon region={r.to} large={true}></RegionIcon>
                    </Box>
                    {t(r.label)}
                  </Box>
                </Link>
              </Grid>
            );
          })}
          <Grid size={{xs: 12}}>
            <Link
              data-test={dataTestLink('alldata')}
              component="a"
              color="text.primary"
              href={`/data/${i18n.resolvedLanguage}/navius_research_regional_data_all.zip`}
              download={'navius_research_regional_data_all.zip'}
            >
              <Box sx={{ textAlign: 'center', pt: 2 }}>
                <Typography variant="h6">{t('download_all_data')}</Typography>
              </Box>
            </Link>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
