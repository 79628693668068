import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import CommonTooltip from './CommonTooltip';
import { ParamsContext } from '../contexts/ParamsContext';

import { Slider } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { PolicyContext } from '../contexts/PolicyContext';
import { dataTestValue } from '../helpers';

function dataTestSlider(item) {
  return dataTestValue('commonslider', 'slider', item);
}

function regularValueLabelComponent(tooltip) {
  return ({ open, value, children }) => {
    return (
      <LabelComponent open={open} value={value} tooltip={tooltip}>
        {children}
      </LabelComponent>
    );
  };
}

function LabelComponent({ open, value, children, tooltip }) {
  const { t } = useTranslation();

  return (
    <CommonTooltip open={open} title={t(tooltip)}>
      {children}
    </CommonTooltip>
  );
}

export default function CommonSlider({ code, label, icon, max, hoverTexts }) {
  const theme = useTheme();
  const paramsContext = useContext(ParamsContext);
  const { policy: currentPolicy } = useContext(PolicyContext);

  const [value, setValue] = useState(paramsContext.getIntParam(code));
  const onChange = useCallback((event, newValue) => {
    setValue(newValue);
  });

  useEffect(() => {
    setValue(paramsContext.getIntParam(code));
  }, [code, paramsContext]);

  const onCommitted = useCallback(
    (event, newValue) => {
      paramsContext.setParam(code, newValue);
    },
    [currentPolicy, code, paramsContext],
  );

  const hoverText = useMemo(() => {
    const current = hoverTexts[value];
    if (typeof current === 'string') {
      return {
        disabled: false,
        text: current,
      };
    } else {
      return {
        disabled: false,
        ...current,
      };
    }
  }, [hoverTexts, value]);

  const tallScreen = useMediaQuery('(min-height:1000px)');

  return (
    <Slider
      data-test={dataTestSlider(label)}
      sx={{
        '&.MuiSlider-root': {
          '&&:hover': {
            color: theme.palette.other.orange,
          },
        },
        '& .MuiSlider-mark': {
          border: 'none',
          borderRadius: 'none',
          bgcolor: theme.palette.other.orange,
          color: theme.palette.primary.main,
          height: theme.spacing(0.5),
          width: theme.spacing(0.5),
        },
        '& .MuiSlider-track': {
          border: 'none',
          color: theme.palette.other.grey1,
        },
        '& .MuiSlider-rail': {
          border: 'none',
          color: theme.palette.other.lightorange,
        },
        '& .MuiSlider-thumb': {
          background: `url(${icon})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: hoverText.disabled
            ? theme.palette.other.grey5
            : theme.palette.other.orange,
          backgroundSize: '65%',
          backgroundPosition: 'center',
          height: theme.spacing(tallScreen ? 5 : 4),
          width: theme.spacing(tallScreen ? 5 : 4),
          [theme.breakpoints.down('sm')]: {
            height: theme.spacing(4),
            width: theme.spacing(4),
          },
          [theme.breakpoints.down('narrow500')]: {
            height: theme.spacing(3.5),
            width: theme.spacing(3.5),
          },
          [theme.breakpoints.down('narrow400')]: {
            height: theme.spacing(3),
            width: theme.spacing(3),
          },
        },
      }}
      aria-label={label}
      value={value}
      valueLabelDisplay="auto"
      components={{ ValueLabel: regularValueLabelComponent(hoverText.text) }}
      step={1}
      marks
      min={0}
      max={max}
      onChange={onChange}
      onChangeCommitted={onCommitted}
    />
  );
}
