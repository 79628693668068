import {
  Box,
  Container,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import React, { useContext, useMemo, useState } from 'react';

import DownloadButton from '../common/DownloadButton.js';
import NaviusLogo from '../common/NaviusLogo.js';
import LanguageButton from './LanguageButton.js';
import Map from '../components/Map';
import ThemeButton from './ThemeButton.js';
import { useTranslation } from 'react-i18next';
import { Link as ReactLink } from 'react-router-dom';
import HelpIcon from '@mui/icons-material/Help';

import { ParamsContext } from '../contexts/ParamsContext.js';
import { ViewContext } from '../contexts/ViewContext';

export default function MobileSidebar({ viewList, regionList, graphMap }) {
  const { t } = useTranslation();
  const { getUrl } = useContext(ParamsContext);
  const { view } = useContext(ViewContext);

  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  const sidebarLinks = useMemo(() => {
    const viewLinks = viewList
      .filter((item) => item.dashboardItem)
      .map((item) => {
        const { to, icon, label } = item;
        return {
          to: getUrl({ view: to }),
          icon,
          label,
          active: view !== null && view === to,
        };
      });
    return viewLinks;
  }, [getUrl, view, viewList]);

  return (
    <Container
      sx={{
        flexDirection: 'column',
        display: 'flex',
        height: '100%',
      }}
      onClick={() => handleDrawerToggle()}
    >
      <Box sx={{ alignSelf: 'end', display: 'inline-flex', py: 2, px: 0 }}>
        <LanguageButton />
        <Box
          sx={{
            alignItems: 'center',
            display: 'inline-flex',
            border: '0.1px solid grey',
            borderRadius: 2,
            mx: 0.5,
          }}
        >
          <IconButton
            sx={{
              color: 'inherit',
              p: 0,
            }}
            component={Link}
            href={t('faq_link')}
          >
            <HelpIcon />
          </IconButton>
        </Box>
        <ThemeButton />
      </Box>
      <List>
        <ListItem>
          {view === 'map' ? (
            <Typography
              align="center"
              sx={{ wordWrap: 'break-word', maxWidth: '190px' }}
            >
              {t('welcometext')}
            </Typography>
          ) : (
            <Map strokeWidth="0.5" width={'100%'} />
          )}
        </ListItem>
        {sidebarLinks.map((item) => {
          const linkParams = item.to.startsWith('http')
            ? { href: item.to }
            : { component: ReactLink, to: item.to };
          return (
            <Link
              key={item.label}
              {...linkParams}
              underline="hover"
              color={item.active ? 'other.orange' : 'primary.contrastText'}
            >
              <ListItem
                divider
                sx={item.active ? { backgroundColor: 'other.grey6' } : {}}
              >
                <ListItemIcon
                  sx={{
                    color: 'inherit',
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={t(item.label)} />
              </ListItem>
            </Link>
          );
        })}
      </List>
      <Box
        sx={{
          p: 2,
          alignSelf: 'center',
        }}
      >
        <DownloadButton
          regionList={regionList}
          viewList={viewList}
          graphMap={graphMap}
        />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: 'inline-flex',
          flexDirection: 'column-reverse',
          pb: 4,
        }}
      >
        <NaviusLogo primary height="100px" />
      </Box>
    </Container>
  );
}
