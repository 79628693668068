import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

import CommonSlider from './CommonSlider';
import CommonTooltip from './CommonTooltip';
import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

function TallSliders({ sliderGroups }) {
  const { t } = useTranslation();
  return (
    <Grid
      container
      direction="column"
      sx={{
        px: 1.5,
      }}
    >
      {sliderGroups.map((sliderGroup) => (
        <Grid
          container
          direction="column"
          columnSpacing={1}
          key={sliderGroup.label}
          sx={{ pb: 3 }}
        >
          <Grid
            size={12}
            sx={{
              alignSelf: 'center',
            }}
          >
            <Typography variant="body" sx={{ fontWeight: 'bold' }}>
              {t(sliderGroup.label)}
            </Typography>
          </Grid>
          {sliderGroup.sliders.map((slider) => (
            <Grid container key={slider.label} size={12}>
              <Grid size={1}></Grid>
              <Grid
                size={5}
                sx={{
                  alignSelf: 'center',
                }}
              >
                <CommonTooltip title={t(slider.tooltip)}>
                  <Typography variant="subtitle2">{t(slider.label)}</Typography>
                </CommonTooltip>
              </Grid>
              <Grid size={6}>
                <CommonSlider
                  code={slider.code}
                  max={slider.max}
                  label={slider.label}
                  icon={slider.icon}
                  hoverTexts={slider.hoverTexts}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      ))}
    </Grid>
  );
}

function WideSliders({ sliders }) {
  const { t } = useTranslation();
  const narrowScreen = useMediaQuery('(max-width:400px)');
  const theme = useTheme();
  return (
    <Grid container sx={{}}>
      {sliders.map((slider, index) => (
        <React.Fragment key={slider.label}>
          {!narrowScreen ? (
            <Grid
              size={2.5}
              sx={{
                alignSelf: 'center',
              }}
            >
              <CommonTooltip title={t(slider.tooltip)}>
                <Typography
                  color="text.primary"
                  align="left"
                  variant="h6"
                  sx={{
                    pl: index % 2 !== 0 ? 2 : 0,
                    fontSize: '0.75rem',
                    [theme.breakpoints.between('lg', 'xl')]: {
                      fontSize: '0.75rem',
                    },
                    [theme.breakpoints.between('md', 'lg')]: {
                      fontSize: '0.65rem',
                    },
                    [theme.breakpoints.down('sm')]: {
                      fontSize: '0.8rem',
                    },
                    [theme.breakpoints.down('narrow500')]: {
                      fontSize: '0.7rem',
                    },
                    [theme.breakpoints.down('narrow400')]: {
                      fontSize: '0.6rem',
                    },
                    [theme.breakpoints.down('narrow300')]: {
                      fontSize: '0.5rem',
                    },
                  }}
                >
                  {t(slider.label)}
                </Typography>
              </CommonTooltip>
            </Grid>
          ) : null}
          <Grid
            size={narrowScreen ? 6 : 3.5}
            sx={{
              py: 0.5,
              pr: index % 2 === 0 ? 3 : 1.5,
              pl: index % 2 !== 0 ? 3 : 1.5,
            }}
          >
            <CommonSlider
              code={slider.code}
              max={slider.max}
              label={slider.label}
              icon={slider.icon}
              hoverTexts={slider.hoverTexts}
            />
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
}

export default function SlidersBox({ sliders: sliderGroups }) {
  //const tallScreen = useMediaQuery("(min-height:1000px)");
  const tallScreen = true;

  return tallScreen ? (
    <TallSliders sliderGroups={sliderGroups} />
  ) : (
    <WideSliders sliderGroups={sliderGroups} />
  );
}
