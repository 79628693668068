import { Box, Collapse, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useContext, useMemo } from 'react';

import { Link } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import Aligner from '../common/Aligner';
import Footer from './Footer';
import GraphPage from './GraphPage';
import Map from './Map';
import MobileSettings from './MobileSettings';
import Settings from './Settings';
import DownloadButton from '../common/DownloadButton';

import { DeviceContext } from '../Contexts';
import { ParamsContext } from '../contexts/ParamsContext';
import { PolicyContext } from '../contexts/PolicyContext';
import { RegionContext } from '../contexts/RegionContext';
import { ViewContext } from '../contexts/ViewContext';

export default function ViewPage({
  graphMap,
  graphDataUrl,
  regionList,
  policyList,
  viewList,
  sliders,
  headerHeight,
  isSettingsOpened,
}) {
  const isMobileView = useContext(DeviceContext);
  const { getUrl } = useContext(ParamsContext);
  const { region } = useContext(RegionContext);
  const { view } = useContext(ViewContext);
  const { policy } = useContext(PolicyContext);

  const footerHeight = '88px';
  const headerFooterHeight = `calc(${headerHeight} + ${footerHeight})`;
  const withoutHeaderHeight = `calc(100vh - ${headerHeight})`;
  const withoutHeaderFooterHeight = `calc(100vh - ${headerFooterHeight})`;
  const withoutHeaderHalfHeight = `calc(50vh - ${headerHeight})`;
  const withoutHeaderHalfHeightWithBuffer = `calc(50vh)`;

  const footerLinks = useMemo(() => {
    const links = [
      {
        name: 'region',
        list: regionList.map((item) => {
          return {
            label: item.label,
            icon: item.icon,
            active: item.to === region,
            url: getUrl({ region: item.to }),
          };
        }),
      },
      {
        name: 'policy',
        list: policyList.map((item) => {
          return {
            label: item.label,
            active: item.to === policy,
            url: getUrl({ policy: item.to }),
          };
        }),
      },
      {
        name: 'view',
        list: viewList
          .filter((item) => item.dashboardItem === true)
          .map((item) => {
            return {
              label: item.label,
              icon: item.icon,
              active: item.to === view,
              url: getUrl({ view: item.to }),
            };
          }),
      },
    ];

    const filteredLinks = view === 'map' ? links.slice(0, -1) : links;
    if (view === 'markets') {
      filteredLinks[0].list = filteredLinks[0].list.filter(r => r.label === 'region_can');
    }
    return filteredLinks;
  }, [region, policy, view, getUrl, regionList, policyList, viewList]);

  const toggleSettingsUrl = useMemo(() => {
    return getUrl({ settings: isSettingsOpened ? 'closed' : 'open' });
  }, [getUrl, isSettingsOpened]);

  return (
    <Box
      sx={{
        whiteSpace: 'pre-line',
        position: 'fixed',
        bgcolor: 'primary.main',
        color: 'primary.text',
        height: withoutHeaderHeight,
        width: '100%',
        overflow: 'auto',
      }}
    >
      {isMobileView ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: withoutHeaderHeight,
          }}
        >
          {view === 'map' ? (
            <Box sx={{ width: '100vw', height: withoutHeaderFooterHeight }}>
              <Aligner
                middle={
                  <Map
                    height={
                      isSettingsOpened
                        ? withoutHeaderHalfHeight
                        : withoutHeaderHeight
                    }
                    width={'100vw'}
                  />
                }
                zoomed={true}
              />
            </Box>
          ) : (
            <Box
              sx={{
                flexBasis: 0,
                flexGrow: 4,
                flexShrink: 4,
                width: '100%',
                overflow: 'scroll',
              }}
            >
              <>
                <GraphPage
                  isSettingsOpened={isSettingsOpened}
                  graphList={graphMap[view]}
                  graphDataUrl={graphDataUrl}
                  view={view}
                />
                <Box
                  sx={{
                    height: isSettingsOpened
                      ? withoutHeaderHalfHeightWithBuffer
                      : '96px',
                  }}
                ></Box>
              </>
            </Box>
          )}
          <MobileSettings
            isSettingsOpen={isSettingsOpened}
            sliders={sliders}
            policyList={policyList}
          />
        </Box>
      ) : (
        <Grid container>
          <Grid
            size={{xs: isSettingsOpened ? 4 : 1, xl: isSettingsOpened ? 3 : 1}}
            sx={
              view !== 'map'
                ? {
                    alignSelf: 'start',
                    position: 'sticky',
                    height: 'fit-content',
                    top: 0,
                  }
                : {}
            }
          >
            <Box
              sx={{
                textAlign: isSettingsOpened ? 'right' : 'left',
              }}
            >
              <IconButton
                sx={{ margin: 1.5 }}
                component={Link}
                to={toggleSettingsUrl}
              >
                <ChevronRightIcon
                  sx={{
                    color: 'other.grey1',
                    transform: isSettingsOpened ? 'rotate(180deg)' : null,
                    transition: 'transform 750ms',
                  }}
                />
              </IconButton>
            </Box>
            <Collapse
              orientation="horizontal"
              in={isSettingsOpened}
              collapsedSize={'0'}
              sx={{
                '& .MuiCollapse-wrapperInner': {
                  width: '100%',
                },
              }}
            >
              <Box sx={{ width: '100%' }}>
                <Settings
                  policyList={policyList}
                  sliders={sliders}
                  footerHeight={footerHeight}
                />
              </Box>
            </Collapse>
          </Grid>
          <Grid
            size={{xs: isSettingsOpened ? 8 : 10, xl: isSettingsOpened ? 9 : 10}}
            sx={
              view === 'map'
                ? { alignSelf: 'start', position: 'sticky', top: 0 }
                : {}
            }
          >
            <Box sx={{ height: 'fit-content' }}>
              {view === 'map' ? (
                <Aligner
                  zoomed={!isSettingsOpened}
                  middle={
                    <Map
                      width="100%"
                      height={
                        isSettingsOpened
                          ? `calc(89vh - ${headerFooterHeight})`
                          : withoutHeaderFooterHeight
                      }
                    />
                  }
                />
              ) : (
                <GraphPage
                  graphDataUrl={graphDataUrl}
                  graphList={graphMap[view]}
                  isSettingsOpened={isSettingsOpened}
                  footerHeight={footerHeight}
                  view={view}
                />
              )}
            </Box>
          </Grid>
          <Footer
            links={footerLinks}
            downloadButton={
              <DownloadButton
                regionList={regionList}
                viewList={viewList}
                graphMap={graphMap}
              />
            }
          />
        </Grid>
      )}
    </Box>
  );
}
