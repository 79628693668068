import { Box, CircularProgress, Link, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import Plot from 'react-plotly.js';
import Plotly from 'plotly.js-dist-min';
import { fromJS, Iterable } from 'immutable';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';

function orderedMapReviver(key, value) {
  return Iterable.isKeyed(value) ? value.toOrderedMap() : value.toList();
}

function toImmutable(js) {
  return fromJS(js, orderedMapReviver);
}

const colourWay = [
  '264281',
  '258888',
  '044343',
  '005b5c',
  'ff7500',
  'ff983e',
  '5dcdcd',
  '167474',
  '9aafde',
  '803700',
  '2d303d',
  'a2a2a2',
];

function GraphsGrid({
  graphIdGenerator,
  graphList,
  graphDataUrl,
  footerHeight,
  view,
}) {
  return (
    <>
      <Grid
        justifyContent="space-evenly"
        alignItems="center"
        container
        spacing={2}
        sx={{
          height: 'fit-content',
        }}
      >
        {graphList.map((graph, index) => (
          <Grid
            key={graph}
            size={{xs: 12, lg: 6, xl: 4}}
            sx={{
              padding: 0,
              margin: 0,
              width: '100%',
              height: '450px',
              display: 'flex',
              color: 'secondary.contrastText',
            }}
          >
            <IndividualGraphFetch
              plotId={graphIdGenerator(view, index)}
              graph={graph}
              graphDataUrl={graphDataUrl}
            />
          </Grid>
        ))}
      </Grid>
      <Box sx={{ height: footerHeight }} />
    </>
  );
}

function translateValue(t, value) {
  if (typeof value === 'string') {
    if (value.startsWith('trn:')) {
      return t(value.substring(4));
    } else {
      return value;
    }
  } else if (value instanceof Array) {
    return value.map((c) => translateValue(t, c));
  } else if (value instanceof Object) {
    return Object.entries(value).reduce((acc, entry) => {
      acc[translateValue(t, entry[0])] = translateValue(t, entry[1]);
      return acc;
    }, {});
  } else {
    return value;
  }
}

function IndividualGraphFetch({ plotId, graph, graphDataUrl }) {
  const [loaded, setLoaded] = useState(false);
  const [unavailable, setUnavailable] = useState(false);
  const [data, setData] = useState(toImmutable([]));
  const [layout, setLayout] = useState(toImmutable({}));

  useEffect(() => {
    // Guards against multiple invocations. If the fetch call returns after we've been removed, don't update.
    let running = true;
    getData();
    return () => {
      running = false;
    };

    async function getData() {
      setUnavailable((wasUnavailable) => {
        if (wasUnavailable) {
          setLoaded(false);
        }
        return false;
      });
      try {
        const res = await fetch(graphDataUrl(graph));
        if (res.ok) {
          const body = await res.json();

          const { data: newData, layout: newLayout } = body;

          if (running) {
            setIfDiff('fetch data', setData, newData, toImmutable);
            setIfDiff('fetch layout', setLayout, newLayout, toImmutable);
            setLoaded(true);
          }
        } else {
          if (running) {
            setUnavailable(true);
            setLoaded(true);
          }
        }
      } catch (e) {
        if (running) {
          setUnavailable(true);
          setLoaded(true);
        }
      }
    }
  }, [graph, graphDataUrl]);

  return unavailable ? (
    <SmsFailedIcon sx={{ color: 'other.orange', fontSize: '4rem' }} />
  ) : loaded ? (
    <StyledGraph plotId={plotId} data={data} layout={layout} />
  ) : (
    <CircularProgress sx={{ color: 'other.orange' }} />
  );
}

function applyTheme(theme, layout) {
  return {
    paper_bgcolor: theme.palette.primary.main,
    plot_bgcolor: theme.palette.primary.main,
    font: {
      color: theme.palette.primary.contrastText,
    },
    margin: {
      l: 80,
      r: 80,
      b: 40,
      t: 50,
    },
    autoSize: true,
    colorway: colourWay,
    title: 'Title',
    dragmode: false,
    showlegend: true,
    ...layout.toJS(),
  };
}

function StyledGraph({ plotId, data, layout }) {
  const theme = useTheme();
  const [themedLayout, setThemedLayout] = useState(
    toImmutable(applyTheme(theme, layout)),
  );

  useLayoutEffect(() => {
    setIfDiff('styled graph', setThemedLayout, layout, (newValue) =>
      toImmutable(applyTheme(theme, newValue)),
    );
  }, [layout, theme]);

  return <LocalizedGraph plotId={plotId} data={data} layout={themedLayout} />;
}

function LocalizedGraph({ plotId, data, layout }) {
  const { t, resolvedLanguage } = useTranslation();
  const [localizedLayout, setLocalizedLayout] = useState(
    toImmutable(translateValue(t, layout.toJS())),
  );
  const [localizedData, setLocalizedData] = useState(
    toImmutable(translateValue(t, data.toJS())),
  );

  useLayoutEffect(() => {
    setIfDiff('tranlated layout', setLocalizedLayout, layout, (newValue) =>
      toImmutable(translateValue(t, newValue.toJS())),
    );
  }, [layout, t, resolvedLanguage]);
  useLayoutEffect(() => {
    setIfDiff('translated data', setLocalizedData, data, (newValue) =>
      toImmutable(translateValue(t, newValue.toJS())),
    );
  }, [data, t, resolvedLanguage]);
  return (
    <StatefulGraph
      plotId={plotId}
      data={localizedData}
      layout={localizedLayout}
    />
  );
}

function setIfDiff(name, setter, newValue, transform) {
  if (newValue !== undefined) {
    setter((oldValue) => {
      const newImmutable = toImmutable(newValue);
      const oldImmutable = toImmutable(oldValue);
      if (!newImmutable.equals(oldImmutable)) {
        if (transform) {
          return transform(newValue);
        } else {
          return newValue;
        }
      } else {
        return oldValue;
      }
    });
  }
}

class PlotMap extends Map {
  constructor(defaultValue, entries) {
    super(entries);
    this.defaultValue = defaultValue;
  }

  get(key) {
    return super.has(key) ? super.get(key) : this.defaultValue;
  }
}

function PaywallPlot({href, layout, ...others}) {
  const { l, r, t, b } = layout.margin;
  return (
    <Box position="relative" width="100%" height="100%">
      <Plot layout={layout} {...others}/>
      <Box sx={{
        position: "absolute",
        top: t,
        left: l,
        width: `calc(100% - ${l + r}px)`,
        height: `calc(100% - ${t + b}px)`,
        backdropFilter: "blur(10px)",
        pointerEvents: "none"
      }}>
      </Box>
      <Link
        href={href}
        underline="hover"
        rel="noopener noreferer"
        target="_blank"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 0.5,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: 'translateX(-50%)',
          color: 'text.primary',
          backgroundColor: 'primary.main'
        }}
      >
        <Typography>Available with subscription.</Typography>
        <Typography>Click for more information.</Typography>
      </Link>
    </Box>
  );
}

const plotProps = {
  default: {
    component: Plot,
    props: {}
  },
  paywall: {
    component: PaywallPlot,
    props: {
      href: 'https://www.naviusresearch.com/services/policy-credit-market-forecasts',
    },
  },
}

function prefixIdGenerator(view, index) {
  return `graph-page-display-${view}-${index}`;
}

const plotMap = new PlotMap(
  plotProps.default,
  [4, 5].map(plotId => [prefixIdGenerator('markets', plotId), plotProps.paywall])
);

const defaultConfig = {
  displayModeBar: false,
  responsive: true,
  displaylogo: false,
  scrollZoom: false,
};

function StatefulGraph({ plotId, data: dataProp, layout: layoutProp }) {
  const ref = useRef(null);
  const [config, setConfig] = useState(defaultConfig);
  const [data, setData] = useState(dataProp.toJS());
  const [layout, setLayout] = useState(layoutProp.toJS());
  const [frames, setFrames] = useState([]);

  const updateState = useCallback((figure) => {
    const {
      data: figureData,
      layout: figureLayout,
      frames: figureFrames,
      config: figureConfig,
    } = figure;
    setIfDiff('config', setConfig, figureConfig);
    setIfDiff('data', setData, figureData);
    setIfDiff('frames', setFrames, figureFrames);
    setIfDiff('layout', setLayout, figureLayout);
  });

  useLayoutEffect(() => {
    updateState({
      data: dataProp.toJS(),
      layout: layoutProp.toJS(),
    });
  }, [dataProp, layoutProp]);

  const onInit = useCallback(
    (figure, graphDiv) => {
      ref.current = graphDiv;
      updateState(figure);
    },
    [updateState],
  );

  const onUpdate = useCallback(
    (figure, graphDiv) => {
      ref.current = graphDiv;
      updateState(figure);
    },
    [updateState],
  );

  const plotProps = plotMap.get(plotId);
  return (
    <plotProps.component
      divId={plotId}
      config={config}
      data={data}
      layout={layout}
      frames={frames}
      onInitialized={onInit}
      onUpdate={onUpdate}
      useResizeHandler
      style={{ width: '100%', height: '100%' }}
      {...plotProps.props}
    />
  );
}

export default function GraphPage({
  graphList = [],
  graphDataUrl,
  isSettingsOpened,
  footerHeight,
  view,
}) {
  useEffect(() => {
    // Manually trigger resizing if the settings are collapsed.
    graphList.forEach((g, idx) => {
      const sel = document.querySelector(`#${prefixIdGenerator(view, idx)}`);
      if (sel) {
        Plotly.Plots.resize(sel);
      }
    });
  }, [graphList, isSettingsOpened]);

  return (
    <GraphsGrid
      graphIdGenerator={prefixIdGenerator}
      graphDataUrl={graphDataUrl}
      graphList={graphList}
      footerHeight={footerHeight}
      view={view}
    />
  );
}
