import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/styles';
import { dataTestValue } from '../helpers';

function dataTestA(item) {
  return dataTestValue('naviuslogo', 'a', item);
}

export default function NaviusLogo({ primary, height, sx }) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const invertColor =
    !isDarkMode && primary
      ? {
          filter: 'invert(1)',
        }
      : {};

  return (
    <Box
      sx={{
        ...(sx || {}),
        ...invertColor,
      }}
    >
      <a
        href="https://www.naviusresearch.com"
        data-test={dataTestA('wwwnaviusresearchcom')}
      >
        <img
          src="/images/navius_logo_white.png"
          alt="Navius Research Logo"
          style={{
            maxHeight: height,
            width: '100%',
            objectFit: 'contain',
          }}
        />
      </a>
    </Box>
  );
}
