import { ReactComponent as AB } from './assets/images/regions/AB.svg';
import { ReactComponent as BC } from './assets/images/regions/BC.svg';
import { ReactComponent as CANADA } from './assets/images/regions/BlackMapWithNoTitles.svg';
import { ReactComponent as ECONOMY } from './assets/images/icons/icon_economy.svg';
import { ReactComponent as ELECTRICITY } from './assets/images/icons/icon_electricity.svg';
import { ReactComponent as ENERGY } from './assets/images/icons/icon_energy.svg';
import { ReactComponent as MARKETS } from './assets/images/icons/icon_markets.svg';
import { ReactComponent as MB } from './assets/images/regions/MB.svg';
import { ReactComponent as NB } from './assets/images/regions/NB.svg';
import { ReactComponent as NL } from './assets/images/regions/NL.svg';
import { ReactComponent as NS } from './assets/images/regions/NS.svg';
// import { ReactComponent as NT } from './assets/images/regions/NT.svg';
// import { ReactComponent as NV } from './assets/images/regions/NV.svg';
import { ReactComponent as ON } from './assets/images/regions/ON.svg';
import { ReactComponent as PE } from './assets/images/regions/PE.svg';
import { ReactComponent as QC } from './assets/images/regions/QC.svg';
import { ReactComponent as SK } from './assets/images/regions/SK.svg';
import { ReactComponent as TECHNOLOGY } from './assets/images/icons/icon_technology.svg';
// import { ReactComponent as YT } from "./assets/images/regions/YT.svg";
import { SvgIcon } from '@mui/material';
import React from 'react';

import { AutomaticRegionIcon } from './components/RegionIcon.js';

const viewList = [
  {
    label: ' ', //space needed for colors to work
    color: 'other.white',
    to: 'map',
    icon: <AutomaticRegionIcon large={false} />,
    largeIcon: <AutomaticRegionIcon large={true} />,
    dashboardItem: false,
    regional: true,
  },
  {
    label: 'view_electricity',
    color: 'other.grey1',
    to: 'electricity',
    icon: <SvgIcon component={ELECTRICITY} inheritViewBox />,
    largeIcon: <SvgIcon component={ELECTRICITY} inheritViewBox fontSize="large" />,
    dashboardItem: true,
  },
  {
    label: 'view_energy_and_emissions',
    color: 'other.grey2',
    to: 'energy',
    icon: <SvgIcon component={ENERGY} inheritViewBox />,
    largeIcon: <SvgIcon component={ENERGY} inheritViewBox fontSize="large" />,
    dashboardItem: true,
  },
  {
    label: 'view_technology',
    color: 'other.grey3',
    to: 'technology',
    icon: <SvgIcon component={TECHNOLOGY} inheritViewBox />,
    largeIcon: <SvgIcon component={TECHNOLOGY} inheritViewBox fontSize="large" />,
    dashboardItem: true,
  },
  {
    label: 'view_economy',
    color: 'other.grey4',
    to: 'economy',
    icon: <SvgIcon component={ECONOMY} inheritViewBox />,
    largeIcon: <SvgIcon component={ECONOMY} inheritViewBox fontSize="large" />,
    dashboardItem: true,
  },
  {
    label: 'view_markets',
    color: 'other.grey5',
    to: 'markets',
    icon: <SvgIcon component={MARKETS} inheritViewBox />,
    largeIcon: <SvgIcon component={MARKETS} inheritViewBox fontSize="large" />,
    dashboardItem: true,
  },
];

const regionList = [
  {
    label: 'region_can',
    to: 'Canada',
    icon: <SvgIcon component={CANADA} inheritViewBox />,
    regional_data: 'navius_research_regional_data_can.zip',
  },
  {
    label: 'region_ab',
    to: 'AB',
    icon: <SvgIcon component={AB} inheritViewBox />,
    regional_data: 'navius_research_regional_data_ab.zip',
  },
  {
    label: 'region_bc',
    to: 'BC',
    icon: <SvgIcon component={BC} inheritViewBox />,
    regional_data: 'navius_research_regional_data_bc.zip',
  },
  {
    label: 'region_mb',
    to: 'MB',
    icon: <SvgIcon component={MB} inheritViewBox />,
    regional_data: 'navius_research_regional_data_mb.zip',
  },
  {
    label: 'region_nb',
    to: 'NB',
    icon: <SvgIcon component={NB} inheritViewBox />,
    regional_data: 'navius_research_regional_data_nb.zip',
  },
  {
    label: 'region_nl',
    to: 'NL',
    icon: <SvgIcon component={NL} inheritViewBox />,
    regional_data: 'navius_research_regional_data_nl.zip',
  },
  {
    label: 'region_ns',
    to: 'NS',
    icon: <SvgIcon component={NS} inheritViewBox />,
    regional_data: 'navius_research_regional_data_ns.zip',
  },
  {
    label: 'region_on',
    to: 'ON',
    icon: <SvgIcon component={ON} inheritViewBox />,
    regional_data: 'navius_research_regional_data_on.zip',
  },
  {
    label: 'region_pe',
    to: 'PE',
    icon: <SvgIcon component={PE} inheritViewBox />,
    regional_data: 'navius_research_regional_data_pe.zip',
  },
  {
    label: 'region_qc',
    to: 'QC',
    icon: <SvgIcon component={QC} inheritViewBox />,
    regional_data: 'navius_research_regional_data_qc.zip',
  },
  {
    label: 'region_sk',
    to: 'SK',
    icon: <SvgIcon component={SK} inheritViewBox />,
    regional_data: 'navius_research_regional_data_sk.zip',
  },
];

const policyList = [
  {
    label: 'policy_label_current',
    tooltip: 'policy_tooltip_current',
    to: 'current',
  },
  {
    label: 'policy_label_announced',
    tooltip: 'policy_tooltip_announced',
    to: 'announced',
  },
  {
    label: 'policy_label_netzero',
    tooltip: 'policy_tooltip_netzero',
    to: 'netzero',
  },
];

const sliders = {
  p_wss: {
    group: 1,
    order: 1,
    code: 'wss',
    label: 'slider_label_wss',
    tooltip: 'slider_tooltip_wss',
    max: 2,
    default: 1,
    icon: '/images/icons/icon_solar.svg',
    hoverTexts: [
      'slider_dollar1',
      'slider_dollar2',
      'slider_dollar3',
      'slider_dollar4',
      'slider_dollar5',
    ],
  },
  p_ccs: {
    group: 1,
    order: 2,
    code: 'ccs',
    label: 'slider_label_ccs',
    tooltip: 'slider_tooltip_ccs',
    max: 2,
    default: 1,
    icon: '/images/icons/icon_carbon_cap.svg',
    hoverTexts: [
      'slider_dollar1',
      'slider_dollar2',
      'slider_dollar3',
      'slider_dollar4',
      'slider_dollar5',
    ],
  },
  p_hyd: {
    group: 1,
    order: 3,
    code: 'hyd',
    label: 'slider_label_hyd',
    tooltip: 'slider_tooltip_hyd',
    max: 2,
    default: 1,
    icon: '/images/icons/icon_hydrogen.svg',
    hoverTexts: [
      'slider_dollar1',
      'slider_dollar2',
      'slider_dollar3',
      'slider_dollar4',
      'slider_dollar5',
    ],
  },
  p_oil: {
    group: 2,
    order: 4,
    code: 'oil',
    label: 'slider_label_oil',
    tooltip: 'slider_tooltip_oil',
    max: 1,
    default: 1,
    icon: '/images/icons/icon_oil.svg',
    hoverTexts: ['slider_dollar1', 'slider_dollar2'],
  },
  p_dac: {
    group: 3,
    order: 5,
    code: 'dac',
    label: 'slider_label_dac',
    tooltip: 'slider_tooltip_dac',
    max: 1,
    default: 0,
    icon: '/images/icons/icon_dac.svg',
    hoverTexts: [
      { text: 'slider_not_available', disabled: true },
      'slider_available',
    ],
  },
  p_smr: {
    group: 3,
    order: 6,
    code: 'smr',
    label: 'slider_label_smr',
    tooltip: 'slider_tooltip_smr',
    max: 1,
    default: 0,
    icon: '/images/icons/icon_smr.svg',
    hoverTexts: [
      { text: 'slider_not_available', disabled: true },
      'slider_available',
    ],
  },
};

const sortedSliders = Object.values(sliders).reduce((acc, slider) => {
  if (!acc.hasOwnProperty(slider.group)) {
    acc[slider.group] = [slider];
  } else {
    acc[slider.group].push(slider);
    acc[slider.group].sort((a, b) => {
      return a.order - b.order;
    });
  }
  return acc;
}, {});

const sliderGroups = [
  'sliders_subheading_cost',
  'sliders_subheading_oil',
  'sliders_subheading_emission',
].map((value, idx) => {
  return { label: value, sliders: sortedSliders[idx + 1] };
});

const settings = {
  production: {
    graphRoot: '/graph',
  },
  development: {
    graphRoot: 'http://localhost:4000/graph',
  },
};
const graphRoot = settings[process.env.NODE_ENV].graphRoot;

function prepareParams(params) {
  const plucked = Object.entries(params).reduce((acc, entry) => {
    if (!sliders.hasOwnProperty(entry[0])) {
      throw new Error(`Unrecognized parameter: ${entry[0]}`);
    }
    const found = parseInt(entry[1], 10);
    if (!Number.isInteger(found)) {
      throw new Error(`Invalid parameter value: ${entry[0]} = ${entry[1]}`);
    }
    const slider = sliders[entry[0]];
    acc.push([slider.code, found]);
    return acc;
  }, []);
  return plucked.sort().flat().join('/');
}

function graphDataUrl(graphId, policy, region, params) {
  if (!policy || !region) {
    throw new Error('Policy and region are required when fetching data!');
  }
  const concatenatedParams = prepareParams(params);
  return `${graphRoot}/${graphId}/${policy}/${region}/${concatenatedParams}/data.json`;
}

const graphMap = {
  electricity: [
    'electricity demand by sector',
    'electricity capacity by source',
    'electricity generation by source',
    'electricity price',
    'electricity storage',
    'peak electricity load',
  ],
  energy: [
    'emissions by sector',
    'energy consumption by sector',
    'energy consumption by fuel',
    'change in energy demand',
    'primary energy production',
    'share of energy consumption that is renewable',
  ],
  technology: [
    'total market share of vehicles by type',
    'total market share of residential heating by type',
    'total market share of industrial heat',
    'carbon capture and storage',
    'hydrogen consumption by sector',
    'hydrogen production by type',
  ],
  economy: [
    'GDP by sector',
    'GDP growth rate',
    'low carbon economy GDP growth rate',
    'jobs by sector',
    'low carbon economy GDP',
    'low carbon economy investment',
  ],
  markets: [
    'refining output',
    'refining input',
    'oil prices',
    'natural gas prices',
    'placeholder data 1',
    'placeholder data 2',
  ]
};
export {
  graphMap,
  graphDataUrl,
  policyList,
  regionList,
  viewList,
  sliders,
  sliderGroups,
};
